import React, { useEffect, useState } from "react";
import "../../style/style.css";
import axios from "axios";
import { FaYoutube } from "react-icons/fa";
// import internet from "../../assets/images/myinternet.jpg";
// import myev from "../../assets/images/my-ev.png";
// import weather from "../../assets/images/weather.svg";
// import livebg from "../../assets/images/live.png";
// import icon from "../../assets/images/togle-img.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLanguage } from "../../utils/LanguageContext";
import MenuIcon from "@mui/icons-material/Menu";
// import { Link } from "react-router-dom";
// import NewsSlider from "./LiveNewsSlider";
import LiveNewsSlider from "./LiveNewsSlider";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ReactPlayer from 'react-player';

const Live = () => {
  const { translate } = useLanguage();
  const [liveUrl, setLiveUrl] = useState([])
  const [playingIndex, setPlayingIndex] = useState(null);
  const handlePlay = (index) => {
    setPlayingIndex(index);
  }
  const getLiveNews = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/news/getLive`)
      setLiveUrl(response.data)
    } catch (error) {
      console.log("Error fetching Live:", error)
    }
  }
  useEffect(() => {
    getLiveNews()
  }, [])
  return (
    <div>
      <section>
        <div className="live-news padd features">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <ul className="p-0">
                  <li className="frist_child">
                    <MenuIcon style={{ color: "red", marginRight: "10px", marginBottom: '2px' }} />
                    <strong style={{ fontSize: '18px', textTransform: 'uppercase' }}>
                      {translate("liveHeading")}
                    </strong>
                  </li>
                </ul>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="box">
                      <div className="responsive-iframe-container">
                        {/* <iframe className="w-100 h-100"
                          src={liveUrl[0]?.videoUrl}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        ></iframe> */}
                        <ReactPlayer
                          url={liveUrl[0]?.videoUrl}
                          width="100%"
                          // height="220px"
                          loop={false}
                          playbackRate={1}
                          playing={playingIndex === 0}
                          onPlay={() => handlePlay(0)}
                          controls={true}
                          playIcon={<FaYoutube onClick={() => handlePlay(0)} className="youtube-icon" />}
                        />
                      </div>
                    </div>
                  </div>
                  <LiveNewsSlider liveUrl={liveUrl} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Live;
